import React, { useContext, useState, useEffect } from "react";
import Button from "../component/Button";
import MaterialContext from "../context/material/materialContext";
import ImagesContext from "../context/images/imagesContext";
import Camera from "../component/Camera";
import Alert from "../component/Alert";
import Spinner from "../component/Spinner";
import GradeSelector from "../component/GradeSelector";
import TypeSelector from "../component/TypeSelector";
import HubSelector from "../component/HubSelector";
import Modal from "../component/Modal";
import GalleryPhoto from "../component/GalleryPhoto";
import Memo from "./Memo";
import ToggleSwitch from "../component/ToggleSwitch";

const BookInDetails = props => {
  const materialContext = useContext(MaterialContext);
  const imagesContext = useContext(ImagesContext);

  const {
    generateMaterial,
    loading,
    type,
    grade,
    updateBookIn,
    currentWeighbridgeDocket,
    reelHub,
  } = materialContext;
  const { currentImages, setCurrentImages, setModalImage } = imagesContext;

  const selectedSupplier = JSON.parse(localStorage.getItem("selectedSupplier"));
  const currentBookIn = JSON.parse(localStorage.getItem("currentBookIn"));
  const { name } = currentBookIn;

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // type change modal
  const [openType, setTypeOpen] = useState(false);

  const handleTypeOpen = () => setTypeOpen(true);
  const handleTypeClose = () => setTypeOpen(false);

  useEffect(() => {
    if (
      currentBookIn.category.name &&
      currentBookIn.category.name !== "undefined"
    ) {
      materialContext.type.name !== currentBookIn.category.name &&
        handleTypeOpen();
    }
    // eslint-disable-next-line
  }, [materialContext.type.name]);

  const [materialData, setMaterialData] = useState({
    thickness: currentBookIn.thickness === "0.0" ? "" : currentBookIn.thickness,
    width: currentBookIn.width === "0.0" ? "" : currentBookIn.width,
    diameter: currentBookIn.width === "0.0" ? "" : currentBookIn.width,
    weight: currentBookIn.weight === "0.0" ? "" : currentBookIn.weight,
    materialFrom: currentBookIn.material_from,
    isWeightEst: 0,
    remark: "",
  });

  const {
    thickness,
    width,
    diameter,
    weight,
    materialFrom,
    isWeightEst,
    remark,
  } = materialData;

  const toggleIsWeightEst = () => {
    setMaterialData({ ...materialData, isWeightEst: !isWeightEst });
  };

  const onChange = e =>
    setMaterialData({ ...materialData, [e.target.name]: e.target.value });

  const generateMaterialSubmit = () => {
    updateBookIn(
      name,
      selectedSupplier.id,
      type,
      grade,
      thickness,
      width,
      diameter,
      weight,
      isWeightEst,
      materialFrom,
      remark,
      reelHub,
      currentImages,
      () => {
        generateMaterial(
          selectedSupplier.id,
          currentBookIn.id,
          currentWeighbridgeDocket.id,
          props
        );
        setCurrentImages([]);
      }
    );
  };

  return (
    <>
      <Alert />
      <div className="container">
        {loading ? (
          <Spinner />
        ) : (
          <div className="material">
            <div className="text-center">
              <Button text={name} className="btn btn-mat" />
              <p>Photo from supplier</p>
              <div className="img-gallery">
                {!currentBookIn.images.length
                  ? null
                  : currentBookIn.images.map(image => (
                      <div
                        key={image.id}
                        className="img-gallery-item"
                        onClick={() => {
                          setModalImage(image);
                          handleOpen();
                        }}
                      >
                        <img src={image.url} alt={image.id} />
                      </div>
                    ))}
              </div>
              <hr />
              <HubSelector proc="mat" />
              <GradeSelector proc="mat" />
              <TypeSelector proc="mat" />
              <Camera setCurrentImages={setCurrentImages} proc="mat" />
              {!currentImages.length > 0 && (
                <p>Take photo of belt with book-in number/label</p>
              )}
            </div>
            <hr />
            <div style={{ width: "200px", margin: "0 auto" }}>
              <div className="input-group">
                <span className="input-fix-left">From</span>
                <input
                  className="input-fix"
                  type="text"
                  name="materialFrom"
                  placeholder="e.g. Royhill"
                  value={materialFrom}
                  onChange={onChange}
                  autoComplete="off"
                />
                <span className="input-fix-right"></span>
              </div>
              <div className="input-group">
                <span className="input-fix-left">Thickness</span>
                <input
                  className="input-fix"
                  type="number"
                  name="thickness"
                  value={thickness}
                  onChange={onChange}
                />
                <span className="input-fix-right">mm</span>
              </div>
              <div className="input-group">
                <span className="input-fix-left">Width</span>
                <input
                  className="input-fix"
                  type="number"
                  name="width"
                  value={width}
                  onChange={onChange}
                />
                <span className="input-fix-right">mm</span>
              </div>
              {type.name === "steel" ? (
                <div className="input-group">
                  <span className="input-fix-left">Diameter</span>
                  <input
                    className="input-fix"
                    type="number"
                    name="diameter"
                    placeholder="Steel"
                    value={diameter ? diameter : null}
                    onChange={onChange}
                  />
                  <span className="input-fix-right">mm</span>
                </div>
              ) : null}
              <div className="input-group">
                <span className="input-fix-left">Weight</span>
                <input
                  className="input-fix"
                  type="number"
                  name="weight"
                  value={weight}
                  onChange={onChange}
                />
                <span className="input-fix-right">T</span>
              </div>
              <div className="toggle-switch">
                <ToggleSwitch
                  name="isWeighEst"
                  value={isWeightEst}
                  onChange={toggleIsWeightEst}
                />
                <span>Weight estimated</span>
              </div>
              <div className="input-group">
                <input
                  className="input-text"
                  type="text"
                  name="remark"
                  placeholder="Remarks"
                  value={remark}
                  onChange={onChange}
                />
              </div>
              {!thickness || !width || !weight ? (
                <Button
                  text="Create Material ID"
                  className="btn btn-mat btn-disabled"
                />
              ) : (
                <Button
                  text="Create Material ID"
                  className="btn btn-mat"
                  onClick={() => {
                    generateMaterialSubmit();
                  }}
                />
              )}
              <Button
                text="Back"
                className="btn btn-mat"
                onClick={() => props.history.goBack()}
              />
            </div>
          </div>
        )}
      </div>
      <Modal
        proc="fs-modal"
        open={open}
        onClose={handleClose}
        component={<GalleryPhoto isMemo={true} />}
      />
      <Modal
        open={openType}
        component={
          <>
            <Memo
              placeholder="Reason for changing Type"
              proc="mat"
              title={currentBookIn.name}
              memoTitle="Please specify the reason for changing material type, and attach photo(s)"
              id={currentBookIn.id}
              type="Material"
              isCamera={true}
              onSubmit={() => console.log("submit type change")}
              onClose={handleTypeClose}
            />
          </>
        }
      />
    </>
  );
};

export default BookInDetails;
