// AUTH
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGOUT = "LOGOUT";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
// SUPPLIER
export const GET_SUPPLIERS = "GET_SUPPLIERS";
export const SET_SUPPLIER = "SET_SUPPLIER";
export const UNSET_SUPPLIER = "UNSET_SUPPLIER";
// MATERIAL
export const SET_MATERIAL = "SET_MATERIAL";
export const CREATE_MATERIAL = "CREATE_MATERIAL";
export const GET_PENDING_MATERIAL = "GET_PENDING_MATERIAL";
export const GET_STOCK_MATERIAL = "GET_STOCK_MATERIAL";
export const GET_TYPES = "GET_TYPES";
export const SET_TYPE = "SET_TYPE";
export const GET_GRADES = "GET_GRADES";
export const SET_GRADE = "SET_GRADE";
export const GET_SITES = "GET_SITES";
export const SET_SITE = "SET_SITE";
export const CREATE_BOOK_IN = "CREATE_BOOK_IN";
export const GET_PENDING_BOOK_IN = "GET_PENDING_BOOK_IN";
export const SET_CURRENT_BOOK_IN = "SET_CURRENT_BOOK_IN";
export const GET_PRODUCTED_MATERIAL = "GET_PRODUCTED_MATERIAL";
export const GET_REEL_HUB_LIST = "GET_REEL_HUB_LIST";
export const SET_REEL_HUB = "SET_REEL_HUB";
export const SET_WEIGHBRIDGE_DOCKET = "SET_WEIGHBRIDGE_DOCKET";
export const GET_WEIGHBRIDGE_DOCKETS = "GET_WEIGHBRIDGE_DOCKETS";
export const SET_RECEIVING_TO = "SET_RECEIVING_TO";

// IMAGE
export const SET_CURRENT_IMAGES = "SET_CURRENT_IMAGES";
export const SET_MODAL_IMAGE = "SET_MODAL_IMAGE";
export const SET_PRELOAD_IMAGES = "SET_PRELOAD_IMAGES";
export const SET_MEMO_IMAGES = "SET_MEMO_IMAGES";
export const DELETE_MEMO_IMAGE = "DELETE_MEMO_IMAGE";
export const DELETE_IMAGE = "DELETE_IMAGE";
export const SET_IMAGE_STRING = "SET_IMAGE_STRING";
// ORDERS
export const GET_ORDERS = "GET_ORDERS";
export const GET_LOCAL_ORDERS = "GET_LOCAL_ORDERS";
export const SET_ORDER = "SET_ORDER";
export const SET_CONTAINER = "SET_CONTAINER";
export const GET_CONTAINERS = "GET_CONTAINERS";
export const LOAD_PRODUCT = "LOAD_PRODUCT";
export const UNLOAD_PRODUCT = "UNLOAD_PRODUCT";
export const SET_NEW_WB_TO_PRINT = "SET_NEW_WB_TO_PRINT";
export const GET_PENDING_EXPORT_WEIGHBRIDGE_DOCKETS =
  "GET_PENDING_EXPORT_WEIGHBRIDGE_DOCKETS";
// ALERT
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
// PRODUCTS
export const GET_PENDING_WEIGH = "GET_PENDING_WEIGH";
export const SET_CURRENT_PRODUCT = "SET_CURRENT_PRODUCT";
export const SET_INIT_PRODUCTS = "SET_INIT_PRODUCTS";
export const CREATE_NEW_PRODUCT = "CREATE_NEW_PRODUCT";
export const CONFIRM_PRODUCT = "CONFIRM_PRODUCT";
export const SET_PROCESSING_PRODUCTS = "SET_PROCESSING_PRODUCTS";
export const STOCK_IN_PRODUCT = "STOCK_IN_PRODUCT";
export const BATCH_TO_WEIGH = "BATCH_TO_WEIGH";
export const TOGGLE_JOINT = "TOGGLE_JOINT";
export const STOCK_PRODUCTS = "STOCK_PRODUCTS";
// MEMO
export const SET_MEMO = "SET_MEMO";
export const CLEAR_MEMO = "CLEAR_MEMO";
// GENERAL
export const SET_LOADING = "SET_LOADING";
export const UNSET_LOADING = "UNSET_LOADING";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
// export const CLEAR_STATE = 'CLEAR_STATE';
// TASKS
export const GET_TASK = "GET_TASK";
export const GET_TASKS = "GET_TASKS";
export const COMPLETE_TASK = "COMPLETE_TASK";
