import React, { useContext, useReducer } from "react";
import MaterialReducer from "./materialReducer";
import MaterialContext from "./materialContext";
import AlertContext from "../alert/alertContext";
import axios from "axios";
import {
  SET_TYPE,
  SET_GRADE,
  GET_SITES,
  SET_SITE,
  SET_LOADING,
  UNSET_LOADING,
  CREATE_MATERIAL,
  SET_MATERIAL,
  GET_PENDING_MATERIAL,
  GET_STOCK_MATERIAL,
  CREATE_BOOK_IN,
  GET_PENDING_BOOK_IN,
  SET_CURRENT_BOOK_IN,
  GET_PRODUCTED_MATERIAL,
  SET_REEL_HUB,
  GET_WEIGHBRIDGE_DOCKETS,
  SET_WEIGHBRIDGE_DOCKET,
  SET_RECEIVING_TO,
  // CLEAR_STATE,
} from "../types";
// import axios from "axios";

const MaterialState = props => {
  const alertContext = useContext(AlertContext);

  const { setAlert } = alertContext;
  const initialState = {
    currentMaterial: "",
    pendingMaterial: [],
    pendingBookIn: [],
    currentBookIn: "",
    currentWeighbridgeDocket: "",
    pendingWeighbridgeDockets: [],
    stockMaterial: [],
    productedMaterial: [],
    reelHubList: [],
    to: "",
    reelHub: "",
    types: [],
    type: "",
    grades: [],
    grade: "",
    sites: [],
    site: "",
    loading: false,
  };

  const [state, dispatch] = useReducer(MaterialReducer, initialState);

  // get mapping values of needed data
  const getMappingValues = async (model, field, type) => {
    const endpoint = `api/v1/mapping_values?model=${model}&field=${field}`;

    try {
      const res = await axios({
        method: "GET",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      // console.log(res);
      if (res.status === 200) {
        dispatch({ type: type, payload: res.data.data });
      } else {
        setAlert("Server error, please try again", "danger");
      }
    } catch (err) {
      setAlert("Server error, please try again", "danger");
      console.log(err);
    }
  };

  // get mapping values of all material catetory/types
  const getTypes = async () => {
    await getMappingValues("Material", "category", "GET_TYPES");
  };

  // get mapping value of all material grade
  const getGrades = async () => {
    await getMappingValues("Grade", "name", "GET_GRADES");
  };

  // get mapping value of all reel/hub items
  const getReelHubList = async () => {
    await getMappingValues("HubCategory", "name", "GET_REEL_HUB_LIST");
  };

  // get mapping value of all sites
  const getSites = async () => {
    const sites = await getMappingValues("MaterialPlace", "name");
    dispatch({ type: GET_SITES, payload: sites });
  };

  // get pending weighbridge dockets
  const getPendingWeighbridgeDockets = async () => {
    setLoading();
    const endpoint = `api/v1/weighbridge_dockets.json`;

    try {
      const res = await axios({
        method: "GET",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      if (res.status === 200) {
        dispatch({ type: GET_WEIGHBRIDGE_DOCKETS, payload: res.data.dockets });
      } else {
        console.log(res);
        unsetLoading();
        setAlert("Server error", "danger");
      }
    } catch (err) {
      setAlert("Server error, please try again", "danger");
      console.log(err);
    }
  };

  // get pending weighbridge docket details
  const getPendingWeighbridgeDocket = async id => {
    setLoading();
    const endpoint = `api/v1/weighbridge_dockets/${id}.json`;

    try {
      const res = await axios({
        method: "GET",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      // console.log(res);
      if (res.status === 200) {
        dispatch({ type: SET_WEIGHBRIDGE_DOCKET, payload: res.data.docket });
      } else {
        unsetLoading();
        setAlert("Server error", "danger");
      }
    } catch (err) {
      setAlert("Server error, please try again", "danger");
      console.log(err);
    }
  };

  // update/sign weighbridge docket
  const updateWeighbridgeDocket = async (
    id,
    supplierId,
    rego,
    firstGross,
    firstTare,
    driverName,
    transportCompany,
    currentImages,
    signatureString,
    callback
  ) => {
    setLoading();

    firstGross = firstGross ? firstGross : 0;
    firstTare = firstTare ? firstTare : 0;

    const nett = firstGross - firstTare;

    var formData = new FormData();
    var i = currentImages.length;

    for (var x = 0; x < i; x++) {
      formData.append("weighbridge_docket[images][]", currentImages[x]);
    }
    formData.append("weighbridge_docket[supplier_id]", supplierId);
    formData.append("weighbridge_docket[registration_no]", rego);
    formData.append("weighbridge_docket[first_gross]", firstGross);
    formData.append("weighbridge_docket[first_tare]", firstTare);
    formData.append("weighbridge_docket[nett]", nett);
    formData.append("weighbridge_docket[driver_name]", driverName);
    formData.append("weighbridge_docket[transport_company]", transportCompany);
    if (signatureString !== "") {
      formData.append(
        "weighbridge_docket[signature_image][data]",
        signatureString
      );
    }

    const endpoint = `api/v1/weighbridge_dockets/${id}.json`;

    try {
      const res = await axios({
        method: "PATCH",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
        data: formData,
      });
      if (!res.data.success) {
        console.log(res.data);
        unsetLoading();
        setAlert("Server error", "danger");
      } else {
        callback();
        dispatch({ type: SET_WEIGHBRIDGE_DOCKET, payload: "" });
        setAlert("Weighbridge docket updated", "success");
      }
    } catch (err) {
      unsetLoading();
      setAlert("Server error", "danger");
      console.log(err);
    }
  };

  // update/sign weighbridge docket status
  const updateWeighbridgeDocketStatus = async (id, supplierId, isComplete) => {
    setLoading();

    var formData = new FormData();

    formData.append("weighbridge_docket[supplier_id]", supplierId);
    formData.append("is_complete", isComplete);

    const endpoint = `api/v1/weighbridge_dockets/${id}.json`;

    try {
      const res = await axios({
        method: "PATCH",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
        data: formData,
      });
      // console.log(res);
      if (!res.data.success) {
        console.log(res.data);
        unsetLoading();
        setAlert("Server error", "danger");
      } else {
        dispatch({ type: SET_WEIGHBRIDGE_DOCKET, payload: "" });
        setAlert("Weighbridge docket signed", "success");
      }
    } catch (err) {
      unsetLoading();
      setAlert("Server error", "danger");
      console.log(err);
    }
  };

  // create weighbridge docket
  const createWeighbridgeDocket = async (
    supplierId,
    currentImages,
    rego,
    props,
    to,
    callback
  ) => {
    setLoading();
    var formData = new FormData();

    var i = currentImages.length;

    for (var x = 0; x < i; x++) {
      formData.append("weighbridge_docket[images][]", currentImages[x]);
    }
    formData.append("weighbridge_docket[supplier_id]", supplierId);
    formData.append("weighbridge_docket[registration_no]", rego);

    const endpoint = `api/v1/weighbridge_dockets.json`;

    try {
      const res = await axios({
        method: "POST",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
        data: formData,
      });
      if (!res.data.success) {
        unsetLoading();
        setAlert("Server error", "danger");
      } else {
        callback();
        dispatch({ type: SET_WEIGHBRIDGE_DOCKET, payload: res.data.docket });
        props.history.push(to);
      }
    } catch (err) {
      unsetLoading();
      setAlert("Server error", "danger");
      console.log(err);
    }
  };

  // set current weighbridge docket
  const setCurrentWeighbridgeDocket = weighbridgeDocket => {
    dispatch({ type: SET_WEIGHBRIDGE_DOCKET, payload: weighbridgeDocket });
  };

  // get all pending material under designated supplier
  const getPendingMaterial = async supplierId => {
    setLoading();
    const endpoint = `api/v1/suppliers/${supplierId}/materials/pending.json`;

    try {
      const res = await axios({
        method: "GET",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      // console.log(res);
      if (res.status === 200) {
        unsetLoading();
        dispatch({ type: GET_PENDING_MATERIAL, payload: res.data.materials });
      }
    } catch (err) {
      setAlert("Server error, please try again", "danger");
      console.log(err);
    }
  };

  const getMaterial = async (supplierId, materialId, callback) => {
    setLoading();
    const endpoint = `api/v1/suppliers/${supplierId}/materials/${materialId}.json`;

    try {
      const res = await axios({
        method: "GET",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      // console.log(res);
      if (res.status === 200) {
        dispatch({ type: SET_MATERIAL, payload: res.data.material });
        callback();
      }
    } catch (err) {
      setAlert("Server error, please try again", "danger");
      console.log(err);
    }
  };

  // get all in-stock material under designated supplier
  const getStockMaterial = async supplierId => {
    const endpoint = `api/v1/suppliers/${supplierId}/materials/instorage_or_inproduction.json`;

    try {
      const res = await axios({
        method: "GET",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      dispatch({ type: GET_STOCK_MATERIAL, payload: res.data.materials });
    } catch (err) {
      setAlert("Server error, please try again", "danger");
      console.log(err);
    }
  };

  // get all producted material under designated supplier
  const getProductedMaterial = async supplierId => {
    setLoading();
    const endpoint = `api/v1/suppliers/${supplierId}/materials/producted_materials.json`;

    try {
      const res = await axios({
        method: "GET",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      dispatch({ type: GET_PRODUCTED_MATERIAL, payload: res.data.materials });
    } catch (err) {
      setAlert("Server error, please try again", "danger");
      console.log(err);
    }
  };

  // get Current Material info from api
  const getCurrentMaterial = async (supplierId, id, callback) => {
    setLoading();
    const endpoint = `api/v1/suppliers/${supplierId}/materials/${id}.json`;

    try {
      const res = await axios({
        method: "GET",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      // console.log(res);
      if (res.status === 200) {
        const material = res.data.material;

        dispatch({ type: SET_MATERIAL, payload: material });
        dispatch({
          type: SET_TYPE,
          payload: material.category ? material.category : "",
        });
        dispatch({
          type: SET_GRADE,
          payload: material.grade ? material.grade : "",
        });
        callback();
      } else {
        console.log(res);
      }
    } catch (err) {
      setAlert("Server error", "danger");
      console.log(err);
    }
  };

  // pre-cut material ->this is actually a production process, but it changes status of material so it's here
  const preCutMaterial = async (materialId, currentImages) => {
    setLoading();
    var formData = new FormData();

    var i = currentImages.length;

    for (var x = 0; x < i; x++) {
      formData.append("process_record_images[]", currentImages[x]);
    }

    formData.append("process_record_name", "precut");

    const endpoint = `api/v1/materials/${materialId}/precut.json`;

    try {
      const res = await axios({
        method: "POST",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
        data: formData,
      });

      if (!res.data.success) {
        unsetLoading();
        console.log(res);
        setAlert("Server error", "danger");
      }
      console.log(res);
      unsetLoading();
      // setCurrentMaterial(res.material);
      // dispatch({ type: CREATE_MATERIAL, payload: res.data.material });
    } catch (err) {
      unsetLoading();
      setAlert("Server error", "danger");
      console.log(err);
    }
  };

  // set current material in global state
  const setCurrentMaterial = async material => {
    dispatch({ type: SET_MATERIAL, payload: material });
  };

  // create bookin id - by factory
  const createBookIn = async (
    supplierId,
    type,
    grade,
    thickness,
    width,
    weight,
    currentImages
  ) => {
    setLoading();
    var formData = new FormData();

    var i = currentImages.length;

    for (var x = 0; x < i; x++) {
      formData.append("bookin[images][]", currentImages[x]);
    }

    formData.append("bookin[supplier_id]", supplierId);
    formData.append("bookin[thickness]", thickness);
    formData.append("bookin[width]", width);
    formData.append("bookin[weight]", weight);
    formData.append("bookin[material_category]", type.value);
    formData.append("bookin[material_grade]", grade.value);

    const endpoint = `api/v1/bookins.json`;

    try {
      const res = await axios({
        method: "POST",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
        data: formData,
      });

      if (!res.data.success) {
        unsetLoading();
        console.log(res);
        setAlert("Server error", "danger");
      } else {
        console.log(res);
        // setCurrentMaterial(res.material);
        dispatch({ type: CREATE_BOOK_IN });
        setAlert(
          "Material booked in, please wait for supplier confirmation",
          "success"
        );
      }
    } catch (err) {
      unsetLoading();
      setAlert("Server error", "danger");
      console.log(err);
    }
  };

  /* BOOK-IN PROCEDURES */

  // book-in procedure: get Current book-in data in state
  const setCurrentBookIn = async bookIn => {
    dispatch({ type: SET_CURRENT_BOOK_IN, payload: bookIn });
    dispatch({
      type: SET_TYPE,
      payload:
        !bookIn.category.name || bookIn.category.name === "undefined"
          ? { name: "", type: "" }
          : bookIn.category,
    });
    dispatch({
      type: SET_GRADE,
      payload:
        !bookIn.grade.name || bookIn.grade.name === "undefined"
          ? { name: "", type: "" }
          : bookIn.grade,
    });
  };

  // book-in procedure: get all pending material under designated supplier
  const getPendingBookIns = async supplierId => {
    setLoading();
    const endpoint = `api/v1/suppliers/${supplierId}/confirmed_bookins.json`;

    try {
      const res = await axios({
        method: "GET",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      // console.log(res);
      dispatch({ type: GET_PENDING_BOOK_IN, payload: res.data.bookins });
    } catch (err) {
      setAlert("Server error, please try again", "danger");
      console.log(err);
    }
  };

  // book-in procedure: workshop receiving - generate material id from book in confirmation page
  const generateMaterial = async (
    supplierId,
    bookinId,
    weighbridgeDocketId,
    props
  ) => {
    // console.log("object");
    setLoading();
    var formData = new FormData();

    formData.append("weighbridge_docket_id", weighbridgeDocketId);

    const endpoint = `api/v1/bookins/${bookinId}/generate_material.json`;

    try {
      const res = await axios({
        method: "POST",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        data: formData,
      });
      // console.log(res);
      if (!res.data.success) {
        unsetLoading();
        console.log(res);
        setAlert("Server error", "danger");
      } else {
        await getCurrentMaterial(supplierId, res.data.material.id, () =>
          props.history.push("/material/update")
        );
        unsetLoading();
        setAlert("Material ID created!", "success");
      }
    } catch (err) {
      unsetLoading();
      setAlert("Server error", "danger");
      console.log(err);
    }
  };

  // update book-in - general PATCH
  const updateBookIn = async (
    bookInName,
    supplierId,
    type,
    grade,
    thickness,
    width,
    weight,
    isWeightEst,
    materialFrom,
    diameter,
    remark,
    reelHub,
    currentImages,
    callback
  ) => {
    setLoading();
    var formData = new FormData();

    if (currentImages.length > 0) {
      var i = currentImages.length;

      for (var x = 0; x < i; x++) {
        formData.append("bookin[images][]", currentImages[x]);
      }
    }

    formData.append("bookin[supplier_id]", supplierId);
    formData.append("bookin[thickness]", thickness);
    formData.append("bookin[width]", width);
    formData.append("bookin[weight]", weight);
    formData.append("bookin[is_weight_estimated]", isWeightEst);
    formData.append("bookin[material_from]", materialFrom);
    formData.append("bookin[material_category]", type.value);
    formData.append("bookin[material_grade]", grade.value);
    formData.append("bookin[diameter]", diameter);
    formData.append("bookin[remark]", remark);
    formData.append("bookin[hub_category_id]	", reelHub.value);

    const endpoint = `api/v1/bookins/${bookInName}.json`;

    try {
      const res = await axios({
        method: "PATCH",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
        data: formData,
      });

      if (!res.data) {
        unsetLoading();
        // console.log(res);
        setAlert("Server error", "danger");
      } else if (res.data.field_errors) {
        console.log(res);
        unsetLoading();
        setAlert(res.data.field_errors[0].message, "danger");
      } else {
        unsetLoading();
        // console.log(res);
        callback();
      }
    } catch (err) {
      unsetLoading();
      console.log(err);
      setAlert("Server error", "danger");
    }
  };

  // // update no-supplier-identifier book-in - by workshop
  // const workshopUpdateBookIn = async (
  //   bookInId,
  //   supplierId,
  //   type,
  //   grade,
  //   thickness,
  //   width,
  //   weight,
  //   currentImages,
  //   props
  // ) => {
  //   setLoading();
  //   var formData = new FormData();

  //   var i = currentImages.length;

  //   for (var x = 0; x < i; x++) {
  //     formData.append("bookin[images][]", currentImages[x]);
  //   }

  //   formData.append("bookin[supplier_id]", supplierId);
  //   formData.append("bookin[thickness]", thickness);
  //   formData.append("bookin[width]", width);
  //   formData.append("bookin[weight]", weight);
  //   formData.append("bookin[material_category]", type);
  //   formData.append("bookin[material_grade]", grade);

  //   const endpoint = `api/v1/bookins/${bookInId}.json`;

  //   try {
  //     const res = await axios({
  //       method: "PATCH",
  //       url: process.env.REACT_APP_DEV_API_URL + endpoint,
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //         "Access-Control-Allow-Origin": "*",
  //       },
  //       data: formData,
  //     });

  //     if (!res.data) {
  //       unsetLoading();
  //       console.log(res);
  //       setAlert("Server error", "danger");
  //     } else if (res.data.field_errors) {
  //       unsetLoading();
  //       setAlert(res.data.field_errors[0].message, "danger");
  //     } else {
  //       unsetLoading();
  //       console.log(res);
  //       setAlert(
  //         "Material booked in, please wait for supplier confirmation",
  //         "success"
  //       );
  //       props.history.push("/");
  //     }
  //   } catch (err) {
  //     unsetLoading();
  //     console.log(err);
  //     setAlert("Server error", "danger");
  //   }
  // };

  // create bookin id - by supplier
  const supplierUpdateBookIn = async (
    bookInName,
    type,
    grade,
    materialFrom,
    thickness,
    width,
    rego,
    remark,
    currentImages,
    props
  ) => {
    setLoading();

    thickness = !thickness ? 0 : thickness;
    width = !width ? 0 : width;

    var formData = new FormData();

    var i = currentImages.length;

    for (var x = 0; x < i; x++) {
      formData.append("bookin[images][]", currentImages[x]);
    }

    formData.append("bookin[material_from]", materialFrom);
    formData.append("bookin[registration_no]", rego);
    formData.append("bookin[thickness]", thickness);
    formData.append("bookin[width]", width);
    formData.append("bookin[remark]", remark);
    formData.append("bookin[material_category]", type.value);
    formData.append("bookin[material_grade]", grade.value);

    const endpoint = `api/v1/supplier_bookins/${bookInName}.json`;

    try {
      const res = await axios({
        method: "PATCH",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
        data: formData,
      });

      if (!res.data.success) {
        unsetLoading();
        console.log(res);
        setAlert("Server error", "danger");
      } else {
        unsetLoading();
        // console.log(res);
        props.history.push(`/supplierbookin/completed/${bookInName}`);
        setAlert("Material booked in", "success");
      }
    } catch (err) {
      unsetLoading();
      setAlert("Server error", "danger");
      console.log(err);
    }
  };

  // get bookin details - supplier
  const getSupplierBookIn = async bookInName => {
    setLoading();
    const endpoint = `api/v1/supplier_bookins/${bookInName}.json`;

    try {
      const res = await axios({
        method: "GET",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      if (!res.data) {
        unsetLoading();
        console.log(res);
        setAlert("Server Error, please try again", "danger");
      } else {
        unsetLoading();
        // console.log(res);
        dispatch({ type: SET_CURRENT_BOOK_IN, payload: res.data.bookin });
      }
    } catch (err) {
      setAlert("Server error", "danger");
      console.log(err);
    }
  };

  // submit material for stock in - generate - by workshop
  const materialIn = async (supplierId, type, grade, currentImages) => {
    setLoading();
    var formData = new FormData();

    var i = currentImages.length;

    for (var x = 0; x < i; x++) {
      formData.append("process_record_images[]", currentImages[x]);
    }

    formData.append("material[material_category]", type.value);
    formData.append("material[material_grade]", grade.value);
    formData.append("process_record_name", "generate");

    const endpoint = `api/v1/suppliers/${supplierId}/materials.json`;

    try {
      const res = await axios({
        method: "POST",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
        data: formData,
      });

      if (!res.data.success) {
        unsetLoading();
        console.log(res);
        setAlert("Server error", "danger");
      }
      console.log(res);
      // setCurrentMaterial(res.material);
      dispatch({ type: CREATE_MATERIAL, payload: res.data.material });
    } catch (err) {
      unsetLoading();
      setAlert("Server error", "danger");
      console.log(err);
    }
  };

  // update material details: stock in - measure & weight ->complete
  const materialUpdate = async (
    supplierId,
    materialId,
    type,
    grade,
    thickness,
    width,
    weight,
    reelHub,
    currentImages,
    callBack
  ) => {
    var formData = new FormData();

    var i = currentImages.length;

    for (var x = 0; x < i; x++) {
      formData.append("process_record_images[]", currentImages[x]);
    }

    formData.append("material[material_category]", type.value);
    formData.append("material[material_grade]", grade.value);
    formData.append("material[thickness]", thickness);
    formData.append("material[width]", width);
    formData.append("material[weight]", weight);
    formData.append("material[hub_category_id]", reelHub.value);
    formData.append("process_record_name", "weigh");

    setLoading();

    const endpoint = `api/v1/suppliers/${supplierId}/materials/${materialId}.json`;

    try {
      const res = await axios({
        method: "PATCH",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
        data: formData,
      });

      if (!res.data.success) {
        unsetLoading();
        console.log(res);
        setAlert("Server error", "danger");
      } else {
        unsetLoading();
        setAlert("Material stocked in!", "success");
        callBack();
      }
      // console.log(res);
      // clearState();
    } catch (err) {
      unsetLoading();
      setAlert("Server error", "danger");
      console.log(err);
    }
  };

  // confirm material arrival: stock in - confirm
  const materialConfirm = async (
    materialId,
    supplierId,
    type,
    grade,
    currentImages
  ) => {
    var formData = new FormData();

    var i = currentImages.length;

    for (var x = 0; x < i; x++) {
      formData.append("process_record_images[]", currentImages[x]);
    }

    formData.append("material[material_category]", type.value);
    formData.append("material[material_grade]", grade.value);
    formData.append("process_record_name", "confirm"); //*wait for Cui to add 'confirm' into the allowed record

    setLoading();

    const endpoint = `api/v1/suppliers/${supplierId}/materials/${materialId}.json`;

    try {
      const res = await axios({
        method: "PATCH",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
        data: formData,
      });

      if (!res.data.success) {
        unsetLoading();
        console.log(res);
        setAlert("Server error", "danger");
      }

      console.log(res);
      unsetLoading();
      dispatch({ type: UNSET_LOADING });
    } catch (err) {
      unsetLoading();
      setAlert("Server error", "danger");
      console.log(err);
    }
  };

  // auto-create product ID in material stock in process - when material already processed by suppliers
  const autoCreateProduct = async materialId => {
    setLoading();
    const endpoint = `api/v1/materials/${materialId}/products/auto_generate.json`;

    try {
      const res = await axios({
        method: "POST",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
          },
        },
      });
      if (!res.data.success) {
        unsetLoading();
        console.log(res);
        setAlert("Server error", "danger");
      } else {
        // console.log(res);
        unsetLoading();
        setAlert("Product stocked in", "success");
      }
    } catch (err) {
      setAlert("Server error", "danger");
      unsetLoading();
      console.log(err);
    }
  };

  // clear context state after stock-in
  // const clearState = () => dispatch({ type: CLEAR_STATE });

  // set current material type/category for creating/updating material
  const setType = type => dispatch({ type: SET_TYPE, payload: type });

  // set current material grade for creating/updating material
  const setGrade = grade => dispatch({ type: SET_GRADE, payload: grade });

  // set current material reel/hub
  const setReelHub = reelHub =>
    dispatch({ type: SET_REEL_HUB, payload: reelHub });

  // set current receiving to page
  const setTo = nextPage => {
    localStorage.setItem("to", JSON.stringify(nextPage));
    dispatch({ type: SET_RECEIVING_TO, payload: nextPage });
  };

  // set current site for creating/updating material
  const setSite = site => dispatch({ type: SET_SITE, payload: site });

  // set loading spinner
  const setLoading = () => dispatch({ type: SET_LOADING });

  // // unset loading spinner
  const unsetLoading = () => dispatch({ type: UNSET_LOADING });

  return (
    <MaterialContext.Provider
      value={{
        currentMaterial: state.currentMaterial,
        pendingMaterial: state.pendingMaterial,
        pendingBookIn: state.pendingBookIn,
        currentBookIn: state.currentBookIn,
        stockMaterial: state.stockMaterial,
        productedMaterial: state.productedMaterial,
        currentWeighbridgeDocket: state.currentWeighbridgeDocket,
        pendingWeighbridgeDockets: state.pendingWeighbridgeDockets,
        grades: state.grades,
        grade: state.grade,
        types: state.types,
        type: state.type,
        sites: state.sites,
        site: state.site,
        loading: state.loading,
        reelHubList: state.reelHubList,
        reelHub: state.reelHub,
        to: state.to,
        getGrades,
        getSites,
        setGrade,
        setSite,
        getTypes,
        setType,
        getReelHubList,
        setReelHub,
        setLoading,
        materialIn,
        materialConfirm,
        getPendingMaterial,
        getPendingBookIns,
        setCurrentMaterial,
        getCurrentMaterial,
        materialUpdate,
        getStockMaterial,
        getMaterial,
        createBookIn,
        updateBookIn,
        supplierUpdateBookIn,
        // workshopUpdateBookIn,
        setCurrentBookIn,
        generateMaterial,
        // clearState,
        autoCreateProduct,
        unsetLoading,
        preCutMaterial,
        getProductedMaterial,
        getSupplierBookIn,
        setTo,
        getPendingWeighbridgeDockets,
        getPendingWeighbridgeDocket,
        setCurrentWeighbridgeDocket,
        updateWeighbridgeDocket,
        updateWeighbridgeDocketStatus,
        createWeighbridgeDocket,
      }}
    >
      {props.children}
    </MaterialContext.Provider>
  );
};

export default MaterialState;
