import {
  GET_TYPES,
  SET_TYPE,
  SET_LOADING,
  UNSET_LOADING,
  SET_MATERIAL,
  GET_GRADES,
  SET_GRADE,
  GET_SITES,
  SET_SITE,
  CREATE_MATERIAL,
  GET_PENDING_MATERIAL,
  GET_STOCK_MATERIAL,
  CREATE_BOOK_IN,
  GET_PENDING_BOOK_IN,
  SET_CURRENT_BOOK_IN,
  GET_PRODUCTED_MATERIAL,
  // CLEAR_STATE,
  GET_REEL_HUB_LIST,
  SET_REEL_HUB,
  GET_WEIGHBRIDGE_DOCKETS,
  SET_WEIGHBRIDGE_DOCKET,
  SET_RECEIVING_TO,
} from "../types";

const MaterialReducer = (state, action) => {
  switch (action.type) {
    case GET_REEL_HUB_LIST:
      return {
        ...state,
        reelHubList: action.payload,
        loading: false,
      };
    case SET_REEL_HUB:
      return {
        ...state,
        reelHub: action.payload,
        loading: false,
      };
    case GET_WEIGHBRIDGE_DOCKETS:
      return {
        ...state,
        pendingWeighbridgeDockets: action.payload,
        loading: false,
      };
    case SET_WEIGHBRIDGE_DOCKET:
      return {
        ...state,
        currentWeighbridgeDocket: action.payload,
        loading: false,
      };
    case SET_RECEIVING_TO:
      return {
        ...state,
        to: action.payload,
        loading: false,
      };
    case GET_TYPES:
      return {
        ...state,
        types: action.payload,
        loading: false,
      };
    case SET_TYPE:
      return {
        ...state,
        type: action.payload,
        loading: false,
      };
    case GET_PENDING_MATERIAL:
      return {
        ...state,
        pendingMaterial: action.payload,
        loading: false,
      };
    case GET_PENDING_BOOK_IN:
      return {
        ...state,
        pendingBookIn: action.payload,
        loading: false,
      };
    case GET_STOCK_MATERIAL:
      return {
        ...state,
        stockMaterial: action.payload,
        loading: false,
      };
    case GET_PRODUCTED_MATERIAL:
      return {
        ...state,
        productedMaterial: action.payload,
        loading: false,
      };
    case GET_GRADES:
      return {
        ...state,
        grades: action.payload,
        loading: false,
      };
    case SET_GRADE:
      return {
        ...state,
        grade: action.payload,
        loading: false,
      };
    case GET_SITES:
      return {
        ...state,
        sites: action.payload,
        loading: false,
      };
    case SET_SITE:
      return {
        ...state,
        site: action.payload,
        loading: false,
      };
    case CREATE_MATERIAL:
    case SET_MATERIAL:
      localStorage.setItem("currentMaterial", JSON.stringify(action.payload));
      return {
        ...state,
        currentMaterial: action.payload,
        loading: false,
      };
    case SET_CURRENT_BOOK_IN:
      localStorage.setItem("currentBookIn", JSON.stringify(action.payload));
      return {
        ...state,
        currentBookIn: action.payload,
        loading: false,
      };
    case CREATE_BOOK_IN:
      return {
        ...state,
        loading: false,
      };
    // case CLEAR_STATE:
    //   return {
    //     ...state,
    //     currentMaterial: '',
    //     currentImages: [],
    //     pendingMaterial: [],
    //     stockMaterial: [],
    //     type: '',
    //     grade: '',
    //     loading: false,
    //   };
    case SET_LOADING:
      return { ...state, loading: true };
    case UNSET_LOADING:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default MaterialReducer;
