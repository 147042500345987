import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "../component/Button";
import MaterialContext from "../context/material/materialContext";
import Alert from "../component/Alert";
import Spinner from "../component/Spinner";

const Material = props => {
  const materialContext = useContext(MaterialContext);

  const {
    getPendingBookIns,
    setCurrentBookIn,
    pendingBookIn,
    loading,
    currentWeighbridgeDocket,
  } = materialContext;

  const selectedSupplier = JSON.parse(localStorage.getItem("selectedSupplier"));

  useEffect(() => {
    getPendingBookIns(selectedSupplier.id);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Alert />
      <div className="container">
        <div className="material">
          <div className="text-center">
            {/* <SupplierSelector proc="btn-mat" /> */}
            <Button text={selectedSupplier.name} className="btn btn-mat" />
            {!selectedSupplier ? null : (
              <div>
                {!pendingBookIn.length ? (
                  <p>No pending book-in under {selectedSupplier.name}</p>
                ) : (
                  <div>
                    <p>Adding to {currentWeighbridgeDocket.name}</p>
                    <Button text="Pending Arrival" className="btn btn-mat" />
                    {loading ? (
                      <Spinner />
                    ) : (
                      <div className="list">
                        {pendingBookIn.map(bookIn => (
                          <div
                            key={bookIn.name}
                            className="list-item"
                            onClick={async () => {
                              await setCurrentBookIn(bookIn);
                              // const route = currentMaterial.need_confirm
                              //   ? 'confirm'
                              //   : 'update';
                              // props.history.push(`/material/${route}`);
                              props.history.push(`/material/bookindetails`);
                            }}
                          >
                            {bookIn.name}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
                {/* <Link to="/material/create">
                  <Button text="Create New" className="btn btn-mat" />
                </Link> */}
              </div>
            )}
          </div>
          <div className="text-center">
            <Link to="/weighbridge-docket">
              <Button text="Back" className="btn btn-mat" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Material;
